const list: HTMLFormElement = document.querySelector('.course-list');
const STORAGE_KEY = 'courseListFilters';

function saveFiltersToLocalStorage() {
    localStorage.removeItem(STORAGE_KEY);
    const formData = new FormData(list);
    const data: { [key: string]: string | string[] } = {};

    formData.forEach((value, key) => {
        if (data[key]) {
            if (Array.isArray(data[key])) {
                (data[key] as string[]).push(value as string);
            } else {
                data[key] = [value as string];
            }
        } else {
            data[key] = value as string;
        }
    });

    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

function loadFiltersFromLocalStorage() {
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (savedData) {
        try {
            const data = JSON.parse(savedData);

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const elements = list.querySelectorAll(`[name="${key}"]`);
                    elements.forEach((element) => {
                        if (element instanceof HTMLInputElement) {
                            if (element.type === 'checkbox' || element.type === 'radio') {
                                if (Array.isArray(data[key])) {
                                    element.checked = data[key].includes(element.value);
                                } else {
                                    element.checked = element.value === data[key];
                                }
                            } else {
                                element.value = data[key] as string;
                            }
                        } else if (element instanceof HTMLSelectElement) {
                            element.value = data[key] as string;
                        }
                    });
                }
            }
            updateAllCounters();
            list.requestSubmit();

        } catch (error) {
            console.error('Failed to parse saved filters:', error);
            clearFiltersFromLocalStorage();
        }
    } else {
        list.requestSubmit();
    }
}
function updateAllCounters() {
    const filterItems = Array.from(list.getElementsByClassName('filter__item') as HTMLCollectionOf<HTMLElement>);

    filterItems.forEach(filterItem => {
        const allCheckboxes = Array.from(filterItem.getElementsByClassName('filter__checkbox') as HTMLCollectionOf<HTMLInputElement>);
        const checkedCount = allCheckboxes.filter(checkbox => checkbox.checked).length;
        updateCounter(filterItem, checkedCount);
    });
}

function clearFiltersFromLocalStorage() {
    localStorage.removeItem(STORAGE_KEY);
}

function addFilterChangeListeners() {
    const inputs = list.querySelectorAll('input, select, textarea');
    inputs.forEach(input => {
        input.addEventListener('change', () => {
            saveFiltersToLocalStorage();
        });
    });
}

function debounce<T extends (...args: any[]) => any>(func: T, timeout: number = 300): (...args: Parameters<T>) => void {
    let timer: ReturnType<typeof setTimeout>;

    return function (this: any, ...args: Parameters<T>): void {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

const submitForm = async (event: Event) => {
    event.preventDefault();
    saveFiltersToLocalStorage();
    const loader: HTMLDivElement = list.querySelector('.loader');
    if (loader?.classList.contains('d-none')) {
        loader?.classList.remove('d-none')
    }

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const data = new URLSearchParams();

    formData.forEach((value, key) => {
        data.append(key, value as string);
    });

    data.append('tx_smediacourse_ajaxlist[currentPageNumber]', list.querySelector('.course-list__loadMoreBtn')?.getAttribute('data-page') as string);

    try {
        const response = await fetch(`${list.getAttribute('action')}`, {
            method: 'POST',
            body: data,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.text();

        const loadMoreButton: HTMLButtonElement = list.querySelector('.course-list__loadMoreBtn');

        loader?.classList.add('d-none')

        if (parseInt(loadMoreButton.dataset.page) > 1) {
            list.querySelector('.course-list__results .course-list__results-wrapper').innerHTML += result;
        } else {
            list.querySelector('.course-list__results .course-list__results-wrapper').innerHTML = result;
        }

        const distanceDropdown: HTMLSelectElement = list.querySelector('.course-list__dropdown');

        openDetails();
        submitPagination();
        initPopup();

        if (list.querySelector<HTMLInputElement>('.filter__input')?.value !== '') {
            if (distanceDropdown?.classList.contains('course-list__dropdown--hidden')) {
                distanceDropdown?.classList.remove('course-list__dropdown--hidden')
            }
        } else {
            if (!distanceDropdown?.classList.contains('course-list__dropdown--hidden')) {
                distanceDropdown?.classList.add('course-list__dropdown--hidden')
            }
        }

        let newCouter = document.querySelector('.newCounter')?.innerHTML;

        const counter: HTMLSpanElement = list.querySelector('.totalResults');
        if (counter) {
            counter.innerHTML = newCouter ?? '0';
        }

        if (parseInt(newCouter) <= (parseInt(loadMoreButton.dataset.page) * 12)) {
            loadMoreButton.classList.add('d-none');
        } else {
            if (loadMoreButton.classList.contains('d-none')) loadMoreButton.classList.remove('d-none')
        }


    } catch (error) {
        console.error('Error:', error);
    }
}

const debouncedFireLiveSearch = debounce((url, searchword, region) => {
    if (searchword.length > 2) {
        fireLiveSearch(url, searchword, region);
    } else {
        const liveSearch: HTMLDivElement = list.querySelector('.liveSearch');
        if (liveSearch?.classList.contains('liveSearch--active')) liveSearch?.classList.remove('liveSearch--active');
        if (liveSearch.innerHTML !== '') {
            liveSearch.innerHTML = '';
        }
    }
});

async function fireLiveSearch(url: string, searchword: string, region: string = '') {
    try {
        const formData = new FormData();
        formData.append('tx_smediacourse_ajaxlist[searchword]', searchword);
        formData.append('tx_smediacourse_ajaxlist[region]', region);


        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.text();

        const liveSearchWrapper: HTMLDivElement = list.querySelector('.liveSearch');

        liveSearchWrapper.innerHTML = result;
        liveSearchWrapper.classList.add('liveSearch--active');

        const resultItems = Array.from(liveSearchWrapper.getElementsByClassName('liveSearch__item') as HTMLCollectionOf<HTMLInputElement>);

        resultItems.forEach((item) => {
            let courseName: HTMLSpanElement = item.querySelector('.liveSearch__description');
            let saveName = courseName.innerHTML;

            courseName.innerHTML = highlight(searchword, courseName.innerHTML);

            item.onclick = () => {
                (list.querySelector('.search__input') as HTMLInputElement).value = saveName;
                fireAfterLiveSearch(list.getAttribute('action'), saveName, region)
            }
        })

        list.querySelector('.search__input').addEventListener('focusout', debounce(() => {
            if (liveSearchWrapper.classList.contains('liveSearch--active')) liveSearchWrapper.classList.remove('liveSearch--active');
        }));

    } catch (error) {
        console.error('Error:', error);
    }
}

async function fireAfterLiveSearch(url: string, searchword: string, region: string = '') {
    try {
        const formData = new FormData();
        formData.append('tx_smediacourse_ajaxlist[searchword]', searchword);
        formData.append('tx_smediacourse_ajaxlist[region]', region);
        formData.append('tx_smediacourse_ajaxlist[showEq]', 'true');


        const response = await fetch(url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.text();

        const loadMoreButton: HTMLButtonElement = list.querySelector('.course-list__loadMoreBtn');

        list.querySelector('.course-list__results .course-list__results-wrapper').innerHTML = result;

        const liveSearchWrapper: HTMLDivElement = list.querySelector('.liveSearch');
        liveSearchWrapper.innerHTML = '';
        liveSearchWrapper.classList.remove('liveSearch--active');

        const distanceDropdown: HTMLSelectElement = list.querySelector('.course-list__dropdown');

        openDetails();
        submitPagination();
        initPopup();

        if (list.querySelector<HTMLInputElement>('.filter__input').value !== '') {
            if (distanceDropdown?.classList.contains('course-list__dropdown--hidden')) {
                distanceDropdown?.classList.remove('course-list__dropdown--hidden')
            }
        } else {
            if (!distanceDropdown?.classList.contains('course-list__dropdown--hidden')) {
                distanceDropdown?.classList.add('course-list__dropdown--hidden')
            }
        }

        let newCouter = document.querySelector('.newCounter')?.innerHTML;

        const counter: HTMLSpanElement = list.querySelector('.totalResults');
        counter.innerHTML = newCouter ?? '0';

        if (parseInt(newCouter) <= (parseInt(loadMoreButton.dataset.page) * 12)) {
            loadMoreButton.classList.add('d-none');
        } else {
            if (loadMoreButton.classList.contains('d-none')) loadMoreButton.classList.remove('d-none')
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

function highlight(searchword: string, courseName: string) {
    const searchwords: string[] = searchword.trim().split(" ").filter(word => word.length > 0);

    for (const word in searchwords) {
        courseName = courseName.replace(
            new RegExp(`(${searchwords[word]})(?!([^<]*)?>)`, 'gi'),
            "<mark>$1</mark>"
        );
    }

    return courseName;
}

if (list) {
    const url = window.location.href;
    localStorage.setItem('currentURL', url);
    var rangeInput: HTMLInputElement = list.querySelector('.filter__range');
    var rangeOutput: HTMLElement = list.querySelector('.filter__range-output');
    var rangeInputValue: string = rangeInput?.value;
    const searchSubmitButton: HTMLButtonElement = list.querySelector('.search__button');
    const locationSubmitButton: HTMLButtonElement = list.querySelector('.filter__input-icon');
    const searchInput: HTMLInputElement = list.querySelector('.search__input');

    list.querySelectorAll('.course-list__settings-button')?.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            let target = e.currentTarget as HTMLButtonElement;

            if (!target.classList.contains('course-list__settings-button--active')) {
                const resultWrapper = list.querySelector('.course-list__results .course-list__results-wrapper');

                target.closest('.course-list__settings-orientation').querySelector('.course-list__settings-button--active')?.classList.remove('course-list__settings-button--active');

                target.classList.add('course-list__settings-button--active');

                if (resultWrapper.classList.contains('course-list__results-wrapper--tiles')) {
                    resultWrapper.classList.remove('course-list__results-wrapper--tiles')
                } else {
                    resultWrapper.classList.add('course-list__results-wrapper--tiles')
                }
            }
        })
    })

    list.querySelector('.course-list__settings-dropdown')?.addEventListener('input', () => {
        submitPagination(true);
        list.requestSubmit();
    });

    searchInput?.addEventListener('input', (event) => {
        const input = event.target as HTMLInputElement;

        debouncedFireLiveSearch(input.dataset.url, input.value, input.dataset.region);
    });

    rangeInput?.addEventListener('input', () => {
        rangeOutput.innerHTML = rangeInput.value + ' km';
    });

    if (searchSubmitButton) {
        searchSubmitButton.onclick = (e) => {
            e.preventDefault();
            submitPagination(true);
            list.requestSubmit();
        }
    }

    if (locationSubmitButton) {
        locationSubmitButton.onclick = () => {
            submitPagination(true);
            list.requestSubmit();
        }
    }

    openDetails();
    updateCheckbox();
    submitPagination();
    resetFilter();
    addFilterChangeListeners();

    list.addEventListener('submit', submitForm);
    const is_back = localStorage.getItem("backFromDetail");
    if (is_back) {
        loadFiltersFromLocalStorage();
        localStorage.removeItem('backFromDetail')
    } else {
        clearFiltersFromLocalStorage();
        list.requestSubmit();
    }
}


function updateCheckbox() {
    const filterItems = Array.from(list.getElementsByClassName('filter__item') as HTMLCollectionOf<HTMLElement>);

    filterItems.forEach(filterItem => {
        if (!filterItem.classList.contains('filter__item--open')) {
            filterItem.querySelector('.filter__title').addEventListener('click', () => {
                if (filterItem.classList.contains('filter__item--toggle')) {
                    filterItems.forEach((filterItem, index) => {
                        if (index > 0) {
                            filterItem.classList.contains('filter__item--mobile') ? filterItem.classList.remove('filter__item--mobile') : filterItem.classList.add('filter__item--mobile')
                        }
                    })
                } else {
                    if (filterItem.classList.contains('filter__item--active')) {
                        filterItem.classList.remove('filter__item--active')
                    } else {
                        filterItem.classList.add('filter__item--active')
                    }
                }
            })
        }


        const allCheckboxes = Array.from(filterItem.getElementsByClassName('filter__checkbox') as HTMLCollectionOf<HTMLInputElement>)

        let counter: number = 0
        allCheckboxes?.forEach(checkbox => {
            if (checkbox.checked) {
                counter++;
                updateCounter(filterItem, counter);
            }

            checkbox.addEventListener('input', () => {
                let checkedBoxes = Array.prototype.slice.call(allCheckboxes).filter(ch => ch.checked == true);

                if (checkbox.checked) {
                    if (checkedBoxes.length <= counter) {
                        counter = checkedBoxes.length - 1;
                    }

                    counter++;
                    updateCounter(filterItem, counter);
                } else {
                    counter--;
                    updateCounter(filterItem, counter);
                }

                submitPagination(true);
                list.requestSubmit();
            })
        })
    })
}

function resetFilter() {
    list.querySelector('.filter__reset')?.addEventListener('click', (e) => {
        e.preventDefault();
        const filterItems = Array.from(list.getElementsByClassName('filter__item') as HTMLCollectionOf<HTMLElement>);

        filterItems.forEach(filterItem => {

            const allCheckboxes = Array.from(filterItem.getElementsByClassName('filter__checkbox') as HTMLCollectionOf<HTMLInputElement>)

            allCheckboxes?.forEach(checkbox => {
                checkbox.removeAttribute('checked');
            })

            updateCounter(filterItem, 0);
        });

        (list.querySelector('.search__input') as HTMLInputElement).removeAttribute('value');
        (list.querySelector('.filter__input') as HTMLInputElement).removeAttribute('value');

        const filterRange: HTMLInputElement = list.querySelector('.filter__range');
        filterRange.removeAttribute('value');
        filterRange.setAttribute('value', '10');
        rangeOutput.innerHTML = filterRange.value + ' km';

        if (list.querySelector('.showPopup')) {
            (list.querySelector('.showPopup') as HTMLInputElement).value = '0';
        }

        if (list.querySelector('.department')) {
            (list.querySelector('.department') as HTMLInputElement).value = '0';
        }

        submitPagination(true);
        list.reset();
        list.requestSubmit();
    })
}

function updateCounter(filterItem: HTMLElement, counter: number) {
    if (filterItem.querySelector('.filter__counter')) {
        if (counter > 0) {
            filterItem.querySelector('.filter__counter').innerHTML = '' + counter + '';
            filterItem.querySelector('.filter__counter').classList.add('filter__counter--active');
        } else {
            if (filterItem.querySelector('.filter__counter').classList.contains('filter__counter--active')) {
                filterItem.querySelector('.filter__counter').classList.remove('filter__counter--active');
            }
        }
    }
}

/**
 *
 * @param reset optional parameter if true reset the pagination count
 */
function submitPagination(reset: boolean = false) {
    const button: HTMLButtonElement = (list.querySelector('.course-list__loadMoreBtn') as HTMLButtonElement);
    let page: number = parseInt(button?.dataset.page);

    if (button) {
        if (reset === true) {
            button.dataset.page = String(1);
        }

        button.onclick = (e) => {
            e.preventDefault();
            page++;
            button.dataset.page = String(page);
            list.requestSubmit();
        }
    }
}

function openDetails() {
    if (document.querySelectorAll('.course-list__item')?.length > 0) {
        document.querySelectorAll('.course-list__item')?.forEach((item) => {
            item.querySelector('.course-list__button')?.addEventListener('click', (e) => {
                e.preventDefault();
                let button = e.currentTarget as HTMLButtonElement;

                if (button.classList.contains('course-list__button--active')) {
                    button.classList.remove('course-list__button--active');
                    item.querySelector('.course-list__additional')?.classList.add('course-list__additional--active');
                    item.classList.add('course-list__item--active');
                    button.textContent = 'Weniger anzeigen';
                } else {
                    button.classList.add('course-list__button--active');
                    item.querySelector('.course-list__additional')?.classList.remove('course-list__additional--active');
                    item.classList.remove('course-list__item--active');
                    button.textContent = 'Mehr erfahren';
                }
            })
        })
    }
}

function initPopup() {
    saveFiltersToLocalStorage();
    const popup = document.querySelector('.popup')
    const searchInput: HTMLInputElement = popup?.querySelector('.popup__location');
    const filterInput: HTMLInputElement = list.querySelector('.filter__input');

    if (popup?.querySelector('.popup__button--close') != undefined) {
        popup.querySelector('.popup__button--close').addEventListener('click', (e) => {
            e.preventDefault();
            popup.classList.add('popup--hidden');
        })
    }

    if (popup?.querySelector('.popup__button--submit') != undefined) {
        popup.querySelector('.popup__button--submit').addEventListener('click', (e) => {
            e.preventDefault();

            const searchfield: string = searchInput.value;

            filterInput.value = searchfield;

            popup.classList.add('popup--hidden');
            list.requestSubmit();
        })
    }


    if (searchInput != undefined) {
        searchInput.addEventListener('keyup', (event) => {
            event.preventDefault();

            if (event.key === 'Enter') {
                filterInput.value = searchInput.value;
                list.requestSubmit();
            }
        })
    }


    if (list.querySelector('.showPopup')) {
        (list.querySelector('.showPopup') as HTMLInputElement).value = '0';
    }
}


